import { CsvHeader } from '../features/geocode/GMaps'
import React from 'react'
import tw from 'tailwind-styled-components'

interface DataTableProps {
  headers: CsvHeader[]
  rows: Record<string, string>[]
  onRowDeleteClick: (rowNumber: number) => void
  editValueFunction?: (
    index: number,
    key: string
  ) => ((value: string) => void) | undefined
  refreshFunction?: (index: number) => () => void
  filter?: (row: Record<string, string>) => boolean,
  numbOfComplete?: Record<string, number> | null
}

const RefreshButton = tw.button`
  btn
  btn-circle
  btn-outline
  btn-xs
`

export const DataTable: React.FC<DataTableProps> = ({
  headers,
  rows,
  onRowDeleteClick,
  editValueFunction,
  refreshFunction,
  filter = () => true,
  numbOfComplete
}: DataTableProps) => {
  return (
    <div className="w-full m-auto max-w-screen-xl overflow-hidden shadow-md">
      <div className="overflow-x-auto">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              {refreshFunction && <th key="refresh-row-column" />}
              <th key="row-number-column" />
              {headers.map((head) => (
                <th key={head.key}>{head.label + ' '} 
                {numbOfComplete? (<span className={numbOfComplete[head.key]/rows.length<0.50 ? 
                'text-yellow-600' : numbOfComplete[head.key]/rows.length===1 ? 'text-green-500':''}>
                  ({numbOfComplete[head.key]}/{rows.length})</span>):''}</th>
              ))}
              <th key="delete-row-column" />
            </tr>
          </thead>
          <tbody>
            {rows.filter(filter).map((row, index) => (
              <tr key={index + Object.values(row).join(' ')}>
                {refreshFunction && (
                  <th>
                    <RefreshButton onClick={refreshFunction(index)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="inline-block w-4 h-4 stroke-current fill-current"
                      >
                        <path d="M23 12c0 1.042-.154 2.045-.425 3h-2.101c.335-.94.526-1.947.526-3 0-4.962-4.037-9-9-9-1.706 0-3.296.484-4.655 1.314l1.858 2.686h-6.994l2.152-7 1.849 2.673c1.684-1.049 3.659-1.673 5.79-1.673 6.074 0 11 4.925 11 11zm-6.354 7.692c-1.357.826-2.944 1.308-4.646 1.308-4.962 0-9-4.038-9-9 0-1.053.191-2.06.525-3h-2.1c-.271.955-.425 1.958-.425 3 0 6.075 4.925 11 11 11 2.127 0 4.099-.621 5.78-1.667l1.853 2.667 2.152-6.989h-6.994l1.855 2.681z" />
                      </svg>
                    </RefreshButton>
                  </th>
                )}
                <th>{index + 1}</th>
                {headers.map((header) => {
                  if (
                    !editValueFunction ||
                    !editValueFunction(index, header.key)
                  ) {
                    return (
                      <td key={header.key} className="text-gray-400">
                        {row[header.key]}
                      </td>
                    )
                  }
                  return (
                    <EditableTd
                      key={header.key}
                      value={row[header.key]}
                      setValue={editValueFunction(index, header.key)!}
                    >
                      {row[header.key]}
                    </EditableTd>
                  )
                })}
                <td>
                  <button
                    className="btn btn-circle btn-outline btn-xs"
                    onClick={() => onRowDeleteClick(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="inline-block w-4 h-4 stroke-current"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const EditableTd: React.FC<{
  value: string
  setValue: (newValue: string) => void
}> = ({ value, setValue }) => {
  return (
    <td
      contentEditable
      suppressContentEditableWarning
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur()
        }
        if (e.key === 'Escape') {
          if (value) {
            e.currentTarget.innerText = value
          }
          e.currentTarget.blur()
        }
      }}
      onBlur={(e) => {
        setValue(e.target.innerText.trim())
      }}
    >
      {value}
    </td>
  )
}
