import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ArticleData } from '../../pages/Knowledge'
import articleFile from './ArticleData.json'
import ReactMarkdown from 'react-markdown'

const ArticleFull: React.FC = () => {
  const { id } = useParams<{ id: any }>()

  const articleData = (articleFile as Record<string, ArticleData>)[id]

  const [mdBody, setMdBody] = useState<string | null>(null)

  useEffect(() => {
    import(`./markdown/${articleData.id}.md`)
      .then((result) => {
        fetch(result.default)
          .then((result) => result.text())
          .then(setMdBody)
          .catch(console.error)
      })
      .catch(console.error)
  })

  return (
    <article className="py-8 max-w-screen-lg w-full m-auto">
      <main>
        <h1 className="text-5xl font-bold">{articleData.header}</h1>
        <h2 className="pt-1 text-2xl text-gray-400">{articleData.subHeader}</h2>
        <img
          alt="Article"
          className="pt-4 object-cover h-auto m-auto"
          src={articleData.imgUrl}
          style={{ maxHeight: '36rem' }}
        />
        {mdBody && (
          <ReactMarkdown
            className="pt-4 w-full"
            children={mdBody}
            components={{
              h1: ({ node, ...props }) => <h1 {...props} className="text-4xl font-bold" />, // eslint-disable-line jsx-a11y/heading-has-content
              h2: ({ node, ...props }) => <h2 {...props} className="text-3xl font-bold pt-6" />, // eslint-disable-line jsx-a11y/heading-has-content
              h3: ({ node, ...props }) => <h3 {...props} className="text-2xl font-bold pt-6" />, // eslint-disable-line jsx-a11y/heading-has-content
              h4: ({ node, ...props }) => <h4 {...props} className="text-xl font-bold pt-4" />, // eslint-disable-line jsx-a11y/heading-has-content
              h5: ({ node, ...props }) => <h5 {...props} className="font-bold pt-4" />, // eslint-disable-line jsx-a11y/heading-has-content
              p: ({ node, ...props }) => <p {...props} className="pt-4" />, // eslint-disable-line jsx-a11y/heading-has-content
              a: ({ node, ...props }) => (
                <a target="_blank" {...props} className="text-purple-900" /> // eslint-disable-line jsx-a11y/anchor-has-content
              ),
              ol: ({ node, ...props }) => <ol {...props} className="list-decimal list-inside" />,
              ul: ({ node, ...props }) => <ul {...props} className="list-disc list-inside" />,
              img: ({ node, alt, ...props }) => (
                <img {...props} className="m-auto py-4" alt={alt} />
              ),
            }}
          />
        )}
      </main>
    </article>
  )
}

export default ArticleFull
