import tw from 'tailwind-styled-components'
import React, { useState } from 'react'

const Header = tw.header`
  fixed
  top-0
  h-navbar
  w-screen
  text-gray-800 
  bg-white
  shadow-lg
  z-50
`

const NavBar = tw.nav`
  h-full
  max-w-screen-lg
  m-auto
  py-2
  flex 
  justify-between
  items-center
`

const NavButtonList = tw.ul`
  hidden
  md:flex
`

const NavButtonText = tw.span`
  font-medium
  text-lg
  block
  cursor-pointer
  hover:text-gray-500
  pl-8
  py-4
`

const NavLogotype = tw.span`
  h-full
  block 
  py-2
  text-5xl
  text-gray-800
  font-black
  uppercase
  hover:text-gray-500
  ml-4
  xs:ml-0
`

const VerticalMenu = tw.div`
  fixed
  top-navbar
  h-full
  w-screen
  z-50
`

const VerticalNavButtonList = tw.ul`
  bg-gray-50
  shadow-lg
`

const VerticalNavOverlay = tw.div`
  bg-black
  bg-opacity-20
  h-full
`

const NavButtonListItem: React.FC<{ href: string }> = ({ href, children }) => {
  return (
    <li>
      <a href={href}>
        <NavButtonText>{children}</NavButtonText>
      </a>
    </li>
  )
}

const VerticalNavButtonListItem: React.FC<{
  href: string
  hideMenu: () => void
}> = ({ href, children, hideMenu }) => {
  return (
    <li>
      <a href={href}>
        <NavButtonText onClick={() => hideMenu()}>{children}</NavButtonText>
      </a>
    </li>
  )
}

export const Navbar: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <Header>
      <NavBar>
        <a href="/">
          <NavLogotype>{process.env.REACT_APP_COMPANY_NAME}</NavLogotype>
        </a>
        <NavButtonList>
          <NavButtonListItem href="/">Geocode Tool</NavButtonListItem>
        </NavButtonList>
        <div className="flex md:hidden items-center p-4">
          <div>
            <button onClick={() => setShowMenu((current) => !current)}>Menu</button>
          </div>
        </div>
        {showMenu && (
          <VerticalMenu>
            <VerticalNavButtonList>
              <VerticalNavButtonListItem href="/" hideMenu={() => setShowMenu(false)}>
                Geocode Tool
              </VerticalNavButtonListItem>
            </VerticalNavButtonList>
            <VerticalNavOverlay onClick={() => setShowMenu(false)} />
          </VerticalMenu>
        )}
      </NavBar>
    </Header>
  )
}
