import { useState } from 'react'
import { GMapsGeocoder } from './GMaps'

const Geocoder = () => {
  const [inputAddress, setInputAddress] = useState(
    '1911 Landings Dr, Mountain View, CA 94043, USA'
  )
  const [outputAddress, setOutputAddress] = useState<any>()

  const submitAddress = (): void => {
    GMapsGeocoder(inputAddress).then((res) => {
      console.log(res)
      setOutputAddress(res)
    })
  }

  return (
    <div>
      <div className="flex items-center">
        <input
          onChange={(e) => setInputAddress(e.target.value)}
          className="appearance-none bg-transparent border-none  text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
          type="text"
          placeholder="Input address to be cleaned"
          value={inputAddress}
        />
        <button
          onClick={(e) => {
            submitAddress()
          }}
          type="button"
          className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        >
          Send
        </button>
      </div>
      <h2>
        <b>Output Address:</b>
        <pre>{JSON.stringify(outputAddress)}</pre>
      </h2>
    </div>
  )
}

export default Geocoder
