import React from 'react'
import { ArticleData } from '../../pages/Knowledge'
import { useHistory } from 'react-router-dom'

interface ArticlePreviewProps {
  articleData: ArticleData
}

const ArticlePreview: React.FC<ArticlePreviewProps> = ({ articleData }: ArticlePreviewProps) => {
  const history = useHistory()
  const baseUrl = '/knowledge'

  return (
    <div
      className="card sm:card-side bordered shadow-lg cursor-pointer hover:shadow-xl"
      onClick={() => history.push(`${baseUrl}/${articleData.id}`)}
    >
      <div className="w-60">
        <figure>
          <img alt="Article" className="object-cover h-60" src={articleData.imgUrl} />
        </figure>
      </div>
      <div className="card-body">
        <h2 className="text-2xl font-bold">{articleData.header}</h2>
        <h3 className=" text-gray-400">{articleData.subHeader}</h3>
        <div className="card-actions">
          <button className="btn btn-primary">Read more</button>
        </div>
      </div>
    </div>
  )
}

export default ArticlePreview
