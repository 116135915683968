import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React from 'react'
import { Home } from './Home'
import Geocoder from '../features/geocode/Geocoder'
import { Navbar } from '../features/navbar/Navbar'
import tw from 'tailwind-styled-components'
import { Footer } from '../features/footer/Footer'
import { AuthProvider } from '../features/auth/AuthProvider'
import Knowledge from './Knowledge'
import ArticleFull from '../features/knowledge/ArticleFull'

const ContentWrapper = tw.div`
  pt-navbar
`

export const Routes: React.FC = () => {
  return (
    <AuthProvider>
      <Navbar />
      <ContentWrapper style={{ minHeight: 'calc(100vh - 16rem)' }}>
        <Router>
          <Switch>
            <Route exact component={Home} path="/" />
            <Route exact component={Geocoder} path="/geocoder" />
            <Route exact component={Knowledge} path="/knowledge" />
            <Route exact component={ArticleFull} path="/knowledge/:id" />
            {/*<Route exact component={SignIn} path="/signin" /> */}
            <Route component={() => <p>Page not found.</p>} />
          </Switch>
        </Router>
      </ContentWrapper>
      <Footer />
    </AuthProvider>
  )
}
