import React from 'react'
import tw from 'tailwind-styled-components'

const FooterWrapper = tw.footer`
  w-full
  min-h-footer
  text-gray-800
  bg-gray-100
  px-8
`

const FooterContainer = tw.div`
  max-w-screen-lg
  m-auto
  grid
  md:grid-cols-3
  md:gap-x-8
`

const FooterSection = tw.div`
  pt-8
`

const FooterSectionTitle = tw.h3`
  font-semibold
  pb-1
  border-b-2
  border-black  
`

const FooterList = tw.ul``

const FooterListNavigationText = tw.span`
  block
  py-1
  hover:text-gray-500
`

const FooterListText = tw.span`
  block
  py-1
`

const FooterListNavigation: React.FC<{ href: string }> = ({ href, children }) => {
  return (
    <li>
      <a href={href}>
        <FooterListNavigationText>{children}</FooterListNavigationText>
      </a>
    </li>
  )
}

export const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <nav>
        <FooterContainer>
          <FooterSection>
            <FooterSectionTitle>About {process.env.REACT_APP_COMPANY_NAME}</FooterSectionTitle>
            <FooterList>
              <FooterListText>
                {process.env.REACT_APP_COMPANY_NAME} is a self-service data enrichment tool. We
                believe in data democracy and the value of high quality data, that means a simple to
                use tool with powerful results.
              </FooterListText>
            </FooterList>
          </FooterSection>
          <FooterSection>
            <FooterSectionTitle>Contact</FooterSectionTitle>
            <FooterList>
              <FooterListText>{process.env.REACT_APP_CONTACT_EMAIL}</FooterListText>
            </FooterList>
          </FooterSection>
          <FooterSection>
            <FooterSectionTitle>Navigation</FooterSectionTitle>
            <FooterList>
              <FooterListNavigation href="/">Geocoding Tool</FooterListNavigation>
              <FooterListNavigation href="/knowledge">Knowledge</FooterListNavigation>
            </FooterList>
          </FooterSection>
        </FooterContainer>
      </nav>
    </FooterWrapper>
  )
}
