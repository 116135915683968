import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'
import { sleep } from '../../util/sleep'

const extractAddressFromResult = (result: google.maps.GeocoderResult) => {
  const address: Address = {
    // Formatted Address
    formattedAddress: result.formatted_address,

    // Google Maps Place ID
    placeId: result.place_id,

    // Plus-code
    plusCodeCompoundCode: result.plus_code?.compound_code,
    plusCodeGlobalCode: result.plus_code?.global_code,

    // GPS Coordinates
    longitude: result.geometry.location.lng().toString(),
    latitude: result.geometry.location.lat().toString(),
  }

  result.address_components.forEach((addressComponent) => {
    addressComponent.types.forEach((componentType) => {
      switch (componentType) {
        case 'country':
          address.countryName = addressComponent.long_name
          address.countryCode = addressComponent.short_name
          break
        case 'administrative_area_level_1':
          address.administrativeAreaLevel1 = addressComponent.long_name
          break
        case 'administrative_area_level_2':
          address.administrativeAreaLevel2 = addressComponent.long_name
          break
        case 'administrative_area_level_3':
          address.administrativeAreaLevel1 = addressComponent.long_name
          break
        case 'locality':
          address.locality = addressComponent.long_name
          break
        case 'sublocality':
        case 'sublocality_level_1':
          address.subLocality = addressComponent.long_name
          break
        case 'postal_code':
          address.postalCode = addressComponent.long_name
          address.trimmedPostalCode = addressComponent.long_name.replaceAll(' ', '').trim()
          break
        case 'postal_town':
          address.postalTown = addressComponent.long_name
          break
        case 'route':
          address.streetName = addressComponent.long_name
          break
        case 'street_number':
          address.streetNumber = addressComponent.long_name
          break
        case 'post_box':
          address.postBox = addressComponent.long_name
          break
      }
    })
  })
  return address
}

const geocode = (address: string): Promise<Address[]> => {
  const geocoder = new google.maps.Geocoder()
  return geocoder.geocode({ address }).then((response) => {
    return response.results.map(extractAddressFromResult) // TODO: Can only handle one result, fix so we can handle more
  })
}

const ERROR_SLEEP = 1000

export const GMapsGeocoder = (address: string): Promise<Address[] | null> => {
  return geocode(address).catch((error) => {
    if (error.code !== 'OVER_QUERY_LIMIT') {
      console.error(`Error received that was not over query limit`, error)
      return null
    }
    console.error(`Error caused by limit, retry ${ERROR_SLEEP} ms`, error)
    return sleep(ERROR_SLEEP).then(() => {
      return geocode(address).catch((error) => {
        console.error(`Error received again, will not retry`, error)
        return null
      })
    })
  })
}

export interface Address {
  // Address components
  countryName?: string // Sverige
  countryCode?: string // SE
  administrativeAreaLevel1?: string //administrative_area_level_1 E.g., Stockholms län
  administrativeAreaLevel2?: string // indicates a second-order civil entity below the country level. Within the United States, these administrative levels are counties
  administrativeAreaLevel3?: string // indicates a third-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels
  locality?: string //indicates an incorporated city or town political entity.
  postalTown?: string // Stockholm
  postalCode?: string // 115 42
  trimmedPostalCode?: string // 11542
  subLocality?: string // Sublocality_1 e.g., Östermalm
  streetAddress?: string
  streetName?: string // Street name e.g., Madängsgatan
  streetNumber?: string //e.g., 6
  postBox?: string

  // Full formatted address
  formattedAddress?: string // Madängsgatan 6, 115 42 Stockholm, Sverige

  // Geo-location coordinates
  latitude?: string
  longitude?: string

  // Place ID - Google maps Place ID
  placeId?: string

  // Plus code (alternative address ID)
  plusCodeCompoundCode?: string
  plusCodeGlobalCode?: string
}

const AddressTitles: Record<keyof Address, string> = {
  streetName: 'Street Name',
  streetNumber: 'Street Number',
  streetAddress: 'Street Address',
  latitude: 'Latitude',
  longitude: 'Longitude',
  postalCode: 'Postal Code',
  postalTown: 'Postal Town',
  subLocality: 'Sub Locality',
  locality: 'Locality',
  countryName: 'Country Name',
  countryCode: 'Country Code',
  formattedAddress: 'Formatted Address',
  trimmedPostalCode: 'Trimmed Postal Code',
  postBox: 'Post Box',
  placeId: 'Google Maps Place ID',
  administrativeAreaLevel1: 'Admisitrative Area Level 1',
  administrativeAreaLevel2: 'Admisitrative Area Level 2',
  administrativeAreaLevel3: 'Admisitrative Area Level 3',
  plusCodeCompoundCode: 'Plus Code Compound Code',
  plusCodeGlobalCode: 'Plus Code Global Code',
}

export const AddressCsvHeaders: CsvHeader[] = Object.entries(AddressTitles).map(([key, value]) => {
  return {
    label: value,
    key,
  }
})

export type CsvHeader = LabelKeyObject

export type CsvRow = Record<string, string>
