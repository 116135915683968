import React from 'react'
import { Enricher } from './Enricher'

export const Home: React.FC = () => {
  return (
    <main>
      <div className="max-w-screen-lg m-auto px-4 py-8">
        <h1 className="text-5xl mb-5 font-bold">Data Enrichment</h1>
        <h2>
          <strong>Datan</strong> is a tool where you can easily enrich your address data with{' '}
          <strong>correctly formatted, high quality address data</strong>
        </h2>
        <ul className="py-4">
          <li>✓ Coordinates (Latitude & Longitude)</li>
          <li>✓ Country Code</li>
          <li>✓ City</li>
          <li>✓ Locality</li>
          <li>✓ Postal Code</li>
          <li>✓ Postal Town</li>
          <li>✓ Formatted Street Address</li>
          <li>... and more</li>
        </ul>
        <p>
          <i>GDPR compliant - no data is stored, everything is managed locally in your browser</i>
        </p>
      </div>
      <Enricher />
    </main>
  )
}
