import ArticlePreview from '../features/knowledge/ArticlePreview'
import articleFile from '../features/knowledge/ArticleData.json'

export interface ArticleData {
  id: string
  header: string
  subHeader?: string
  imgUrl?: string
}

const Knowledge = () => {
  const articles = articleFile as Record<string, ArticleData>

  return (
    <div className="flex flex-col justify-center max-w-screen-lg w-full m-auto pt-4 gap-4">
      {Object.values(articles).map((articleData) => {
        return <ArticlePreview key={articleData.id} articleData={articleData} />
      })}
    </div>
  )
}

export default Knowledge
