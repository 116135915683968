import React, { useEffect, useRef, useState } from 'react'
import {
  Address,
  AddressCsvHeaders,
  CsvHeader,
  CsvRow,
  GMapsGeocoder,
} from '../features/geocode/GMaps'
import { CSVLink } from 'react-csv'
import * as XLSX from 'xlsx'
import { sleep } from '../util/sleep'
import tw from 'tailwind-styled-components'
import { endOfToday, isBefore, parseISO } from 'date-fns'
import { DataTable } from '../shared/DataTable'

const CSV_DELIMITER_REGEX = /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/

const EnricherHero = tw.div`
  flex
  flex-col
  gap-8
  p-4
  mb-16
`

const EnricherHeroContent = tw.div`
  flex
  flex-col
  max-w-screen-lg
  w-full
  m-auto
  lg:flex-row-reverse
  justify-between
  gap-4
`

const EnricherTitle = tw.h2`
  max-w-2xl
  mb-5
  text-5xl
  font-bold
`

const EnricherBody = tw.p`
  w-screen
  lg:max-w-prose
  max-w-full
  mb-5
`

const Stepper = tw.ul`
  steps
  mt-16
`

const EnricherImage = tw.img`
  max-w-sm
  rounded-lg
  w-full
  shadow-2xl
  border-4
  border-white
`

const DropContainer = tw.div`
  flex
  flex-col
  place-items-center
  bg-gray-50	
  hover:bg-gray-100
  pt-2
  pb-4
  rounded-lg
  border-2
  border-dashed	
  border-gray-200
  hover:border-gray-400
  cursor-pointer
`
const EnricherProgress = tw.progress<{ $progress: number }>`
  progress 
  ${({ $progress }) => ($progress === 100 ? 'progress-success' : 'progress-primary')} 
  mt-4
`

const Step = tw.li<{
  $stepNumber: number
  $currentStep: number
  $disabled?: boolean
}>`
  step
  ${({ $stepNumber, $currentStep }) => ($stepNumber <= $currentStep ? 'step-primary' : '')}
    ${({ $disabled = false }) => ($disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
`

const getCellText = (cell: string): string => {
  let value
  try {
    value = decodeURIComponent(escape(cell))
  } catch (exception) {
    value = cell
  }
  if (!value) {
    return ''
  }
  return value
    .replace(
      /[\u{203C}\u{2049}\u{20E3}\u{2122}\u{2139}\u{2194}-\u{2199}\u{21A9}-\u{21AA}\u{231A}-\u{231B}\u{23E9}-\u{23EC}\u{23F0}\u{23F3}\u{24C2}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2600}-\u{2601}\u{260E}\u{2611}\u{2614}-\u{2615}\u{261D}\u{263A}\u{2648}-\u{2653}\u{2660}\u{2663}\u{2665}-\u{2666}\u{2668}\u{267B}\u{267F}\u{2693}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26CE}\u{26D4}\u{26EA}\u{26F2}-\u{26F3}\u{26F5}\u{26FA}\u{26FD}\u{2702}\u{2705}\u{2708}-\u{270C}\u{270F}\u{2712}\u{2714}\u{2716}\u{2728}\u{2733}-\u{2734}\u{2744}\u{2747}\u{274C}\u{274E}\u{2753}-\u{2755}\u{2757}\u{2764}\u{2795}-\u{2797}\u{27A1}\u{27B0}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E7}-\u{1F1EC}\u{1F1EE}-\u{1F1F0}\u{1F1F3}\u{1F1F5}\u{1F1F7}-\u{1F1FA}\u{1F201}-\u{1F202}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F23A}\u{1F250}-\u{1F251}\u{1F300}-\u{1F320}\u{1F330}-\u{1F335}\u{1F337}-\u{1F37C}\u{1F380}-\u{1F393}\u{1F3A0}-\u{1F3C4}\u{1F3C6}-\u{1F3CA}\u{1F3E0}-\u{1F3F0}\u{1F400}-\u{1F43E}\u{1F440}\u{1F442}-\u{1F4F7}\u{1F4F9}-\u{1F4FC}\u{1F500}-\u{1F507}\u{1F509}-\u{1F53D}\u{1F550}-\u{1F567}\u{1F5FB}-\u{1F640}\u{1F645}-\u{1F64F}\u{1F680}-\u{1F68A}]/gu,
      ' '
    )
    .replaceAll(/[|&;$%@"'<>()+,[\]]/g, ' ')
    .trim()
    .split(/\s+/)
    .join(' ')
}

const OLD_FILE_ROWS = '__enricher_old_file_rows'
const OLD_FILE_HEADERS = '__enricher_old_file_headers'
const OLD_FILE_NAME = '__enricher_old_file_name'
const SELECTED_OLD_HEADERS = '__enricher_selected_old_headers'
const SELECTED_NEW_HEADERS = '__enricher_selected_new_headers'
const SEARCH_RESULTS = '__enricher_search_results'
const CLIENT_NUMBER_SEARCHED_ROWS = '__enricher_client_number_searched_rows'
const MAXIMUM_SEARCHED_ROWS = 200

const syncLocalStorage = (key: string, value?: any | null, expiry?: Date) => {
  if (value) {
    localStorage.setItem(
      key,
      JSON.stringify({
        value,
        expiry: expiry?.toISOString(),
      })
    )
  } else {
    localStorage.removeItem(key)
  }
}

const getLocalStorage = (key: string): any | null => {
  const item = localStorage.getItem(key)
  if (!item) {
    return null
  }
  const json = JSON.parse(item)
  if (json?.expiry && isBefore(parseISO(json.expiry), new Date())) {
    localStorage.removeItem(key)
    return null
  }
  return json.value
}

// TODO: See search strings, edit values, retry on result, statistics on results, smarter retry logic on quota, handle duplicates,

export const Enricher: React.FC = () => {
  const uploadFileInputRef = useRef<HTMLInputElement | null>(null)
  const [oldFileCsv, setOldFileCsv] = useState<string | null>(null)
  const [oldFilename, setOldFilename] = useState<string | null>(getLocalStorage(OLD_FILE_NAME))
  const [oldHeaders, setOldHeaders] = useState<CsvHeader[] | null>(
    getLocalStorage(OLD_FILE_HEADERS)
  )
  const [oldRows, setOldRows] = useState<CsvRow[] | null>(getLocalStorage(OLD_FILE_ROWS))
  const [selectedOldHeaders, setSelectedOldHeaders] = useState<string[] | null>(
    getLocalStorage(SELECTED_OLD_HEADERS)
  )
  const [selectedNewHeaders, setSelectedNewHeaders] = useState<string[] | null>(
    getLocalStorage(SELECTED_NEW_HEADERS)
  )
  const [searchResults, setSearchResults] = useState<(Address | null)[] | null>(
    getLocalStorage(SEARCH_RESULTS)
  )
  const [resultFilter, setResultFilter] = useState<
    ((row: Record<string, string>) => boolean) | undefined
  >(undefined)
  const [nSearchedRows, setNSearchedRows] = useState<number | null>(
    searchResults ? searchResults.length : null
  )
  const hasCompletedSearch = (): boolean =>
    nSearchedRows !== null ? nSearchedRows === oldRows?.length : false
  const [searching, setSearching] = useState(hasCompletedSearch())
  const [newHeaders, setNewHeaders] = useState<CsvHeader[] | null>(null)
  const [newRows, setNewRows] = useState<CsvRow[] | null>(null)
  const [step, setStep] = useState(hasCompletedSearch() ? 3 : oldRows !== null ? 2 : 1)
  const [clientNumberSearchedRows, setClientNumberSearchedRows] = useState(
    +getLocalStorage(CLIENT_NUMBER_SEARCHED_ROWS)
  )

  const [isFileOver, setIsFileOver] = useState<boolean>(false)

  const [numbOfComplete, setNumbOfComplete] = useState<Record<string, number> | null>(null)

  useEffect(() => {
    if (
      !oldHeaders ||
      !oldRows ||
      !searchResults ||
      !nSearchedRows ||
      nSearchedRows < oldRows.length
    ) {
      return
    }
    const tempNewHeaders= [...oldHeaders, ...AddressCsvHeaders]
    setNewHeaders(tempNewHeaders)
    const tempNewRows = [
      ...oldRows.map((oldRow, index) => {
        return { ...oldRow, ...searchResults[index] }
      }),
    ]
    setNewRows(tempNewRows)
    
    let tempNumbOfComplete: Record<string, number> = {}
    tempNewHeaders.map(header => tempNumbOfComplete[header.key]=0)
    tempNewRows.forEach((row) => {
      if (row) {
        Object.entries(row).forEach(
          ([rowKey, rowValue]) => {
            if(rowValue) {
              tempNumbOfComplete[rowKey]++
            }
          })
      }
    })
    setNumbOfComplete(tempNumbOfComplete)
    
    
    if (!selectedNewHeaders) {
      setSelectedNewHeaders(tempNewHeaders.map((header) => {
        return header.key
    }))

    }
  }, [nSearchedRows, oldHeaders, oldRows, searchResults]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!oldFileCsv) {
      return
    }
    const allCells = oldFileCsv.split('\n').map((row) => row.split(CSV_DELIMITER_REGEX))
    const headers = allCells[0].map(getCellText)
    const rows = allCells.slice(1, allCells.length - 1)
    setOldHeaders([
      ...headers.map((header) => ({
        key: header,
        label: header,
      })),
    ])

    setOldRows([
      ...rows.map((row) => ({
        ...row
          .map((cell, index) => ({
            [headers[index]]: getCellText(cell),
          }))
          .reduce((prev, current) => {
            return { ...prev, ...current }
          }),
      })),
    ])
    setSelectedOldHeaders(headers)
  }, [oldFileCsv])

  useEffect(() => {
    syncLocalStorage(SEARCH_RESULTS, searchResults)
  }, [searchResults?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    syncLocalStorage(OLD_FILE_NAME, oldFilename)
  }, [oldFilename])

  useEffect(() => {
    syncLocalStorage(OLD_FILE_ROWS, oldRows)
  }, [oldRows])

  useEffect(() => {
    syncLocalStorage(OLD_FILE_HEADERS, oldHeaders)
  }, [oldHeaders])

  useEffect(() => {
    syncLocalStorage(SELECTED_OLD_HEADERS, selectedOldHeaders)
  }, [selectedOldHeaders?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    syncLocalStorage(SELECTED_NEW_HEADERS, selectedNewHeaders)
  }, [selectedNewHeaders?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    syncLocalStorage(CLIENT_NUMBER_SEARCHED_ROWS, clientNumberSearchedRows, endOfToday())
  }, [clientNumberSearchedRows])

  const handleFileUpload = (files: FileList | null) => {
    if (!files) {
      return
    }
    if (files.length !== 1) {
      console.log('Invalid file length', files.length)
      return
    }
    const file = files[0]
    if (!['csv', 'xlsx', 'xls'].includes(file.name.split('.')[1])) {
      console.log('Invalid file type', file.name)
      return
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      const binaryString = event.target!.result
      const workBook = XLSX.read(binaryString, { type: 'binary' })
      const worksheetName = workBook.SheetNames[0]
      const worksheet = workBook.Sheets[worksheetName]
      const data = XLSX.utils.sheet_to_csv(worksheet)
      setOldFileCsv(data)
      setOldFilename(file.name)
      setSearchResults(null)
      setNSearchedRows(null)
      setNewHeaders(null)
      setNewRows(null)
      setSearching(false)
      setSelectedNewHeaders(null)
      setResultFilter(undefined)
      setStep(2)
    }
    reader.readAsBinaryString(file)
  }

  const handleEnrichClick = async () => {
    if (!oldHeaders || !oldRows || !selectedOldHeaders) {
      return
    }
    const results = searchResults ?? []
    setSearching(true)
    const startIndex = nSearchedRows ?? 0
    for (const index in oldRows.slice(startIndex)) {
      const rowIndex = +index + startIndex
      const searchText = Object.entries(oldRows[rowIndex])
        .filter(([key, _]) => selectedOldHeaders.includes(key))
        .map(([_, value]) => value)
        .join(' ')
      if (!searchText) {
        results.push(null)
        return
      }
      const result = await GMapsGeocoder(searchText)
      results.push(result ? result[0] : null)
      setNSearchedRows(results.length)
      setSearchResults(results)
      setClientNumberSearchedRows((current) => current + 1)
      await sleep(250)
    }
    setStep(3)
  }

  const onOldRowDeleteClick = (rowNumber: number) => {
    setOldRows(
      (currentOldRows) => currentOldRows?.filter((row, index) => index !== rowNumber) || null
    )
  }

  const onNewRowDeleteClick = (rowNumber: number) => {
    setNewRows(
      (currentNewRows) => currentNewRows?.filter((row, index) => index !== rowNumber) || null
    )
  }

  function outlierRow(row: Record<string, string>) {
    const countryCounts = newRows!
      .map((row) => row['countryCode'])
      .reduce((prev: Record<string, number>, curr) => {
        prev[curr] = prev[curr] ? prev[curr] + 1 : 1
        return prev
      }, {})
    return countryCounts[row['countryCode']] < newRows!.length / 10
  }

  return (
    <EnricherHero>
      <Stepper>
        <Step
          $stepNumber={1}
          $currentStep={step}
          onClick={() => setStep(1)}
          data-content={oldRows && oldHeaders ? '✓' : '?'}
        >
          Select file
        </Step>
        <Step
          $stepNumber={2}
          $currentStep={step}
          onClick={!oldRows && !oldHeaders ? undefined : () => setStep(2)}
          $disabled={!oldRows && !oldHeaders}
          data-content={hasCompletedSearch() ? '✓' : oldRows && oldHeaders ? '?' : ''}
        >
          Select search columns
        </Step>
        <Step
          $stepNumber={3}
          $currentStep={step}
          onClick={!newHeaders && !newRows ? undefined : () => setStep(3)}
          $disabled={!newHeaders && !newRows}
          data-content={hasCompletedSearch() ? '★' : ''}
        >
          Export enriched file
        </Step>
      </Stepper>
      {step === 1 && (
        <EnricherHeroContent>
          <div className="h-auto">
            <EnricherImage
              alt="example of excel file to be uploaded"
              src="https://firebasestorage.googleapis.com/v0/b/datan-enrichment.appspot.com/o/Excel_Example-min.webp?alt=media&token=bd4c1712-b097-455f-a3cb-cd2eb5531c43"
            />
          </div>
          <div>
            <EnricherTitle>Select file to enrich</EnricherTitle>
            <EnricherBody>
              To start your data enrichment you select an Excel/CSV file with address data:
            </EnricherBody>
            <DropContainer
              className={isFileOver ? 'bg-primary bg-opacity-10 border-primary' : ''}
              onDragOver={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onDragEnter={() => setIsFileOver(true)}
              onDragLeave={() => setIsFileOver(false)}
              onDrop={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsFileOver(false)
                handleFileUpload(e.dataTransfer.files)
              }}
              onClick={() => uploadFileInputRef.current!!.click()}
            >
              <div>
                <strong>Drag & drop file</strong>
              </div>
              <i className="pt-1 pb-2 font-medium text-gray-400">OR</i>
              <input
                style={{ display: 'none' }}
                ref={uploadFileInputRef}
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={(e) => handleFileUpload(e.target.files)}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  uploadFileInputRef.current!!.click()
                  e.stopPropagation()
                }}
              >
                Click to browse file
              </button>
            </DropContainer>
          </div>
        </EnricherHeroContent>
      )}
      {step === 2 && oldRows && oldHeaders && selectedOldHeaders && (
        <>
          <EnricherHeroContent>
            <div className="card w-screen max-w-xs shadow-2xl h-auto">
              <div className="card-body">
                {oldHeaders.map((header) => (
                  <div key={header.key} className="form-control">
                    <label className="label cursor-pointer w-auto">
                      <span className="label-text">{header.label}</span>
                      <input
                        type="checkbox"
                        checked={selectedOldHeaders.includes(header.key)}
                        onChange={() => {
                          if (selectedOldHeaders?.includes(header.key)) {
                            setSelectedOldHeaders((current) =>
                              current!.filter((curr) => curr !== header!.key)
                            )
                          } else {
                            setSelectedOldHeaders((current) => [...current!, header.key])
                          }
                          syncLocalStorage(SELECTED_OLD_HEADERS, selectedOldHeaders)
                        }}
                        disabled={nSearchedRows !== null}
                        className="checkbox"
                      />
                    </label>
                  </div>
                ))}
                <EnricherProgress
                  value={nSearchedRows || 0}
                  max={oldRows.length}
                  $progress={Math.round(((nSearchedRows || 0) / oldRows.length) * 100)}
                />
                <p className="text-right">
                  {nSearchedRows !== oldRows.length
                    ? `${Math.round(((nSearchedRows || 0) / oldRows.length) * 100)} %`
                    : '✓ Finished'}
                </p>
                {!searching && (
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleEnrichClick}
                    disabled={clientNumberSearchedRows + oldRows.length > MAXIMUM_SEARCHED_ROWS}
                  >
                    {clientNumberSearchedRows + oldRows.length > MAXIMUM_SEARCHED_ROWS
                      ? `Exceeds daily quota (max ${MAXIMUM_SEARCHED_ROWS})`
                      : nSearchedRows === null
                      ? 'Start data enrichment'
                      : 'Continue data enrichment'}
                  </button>
                )}
              </div>
            </div>
            <div>
              <EnricherTitle>Select search columns</EnricherTitle>
              <EnricherBody>
                Select which columns the data enrichment will be based on to the right:
              </EnricherBody>
              <EnricherBody>
                <strong>Make sure you de-select non-address related information</strong>, for
                example ID, email, first name, phone number etc. Searches commonly include address
                line, postal code and country. You can see the resulting search text in the table
                below.
              </EnricherBody>
              <EnricherBody>
                <strong>Click the button</strong> to start the data enrichment.
              </EnricherBody>
            </div>
          </EnricherHeroContent>
          <DataTable
            headers={[{ key: 'search_text', label: 'Search text' }, ...oldHeaders]}
            rows={oldRows.map((row) => ({
              search_text:
                '"' +
                Object.entries(row)
                  .filter(([key, value]) => selectedOldHeaders.includes(key) && value)
                  .map(([_, value]) => value)
                  .join(', ') +
                '"',
              ...row,
            }))}
            onRowDeleteClick={onOldRowDeleteClick}
            editValueFunction={(index, key) => {
              if (key === 'search_text') {
                return undefined
              }
              if (searchResults && searchResults?.length > index) {
                return undefined
              }
              return (value) => {
                setOldRows((current) => {
                  current![index][key] = value
                  return [...current!]
                })
                syncLocalStorage(OLD_FILE_ROWS, oldRows)
              }
            }}
          />
        </>
      )}
      {step === 3 &&
        newRows &&
        newHeaders &&
        selectedNewHeaders &&
        oldRows &&
        oldFilename &&
        searchResults &&
        selectedOldHeaders && (
          <>
            <EnricherHeroContent>
              <div className="card w-screen max-w-xs shadow-2xl">
                <div className="card-body h-80 overflow-y-auto">
                  <h3>
                    <b>Select columns to export</b>
                  </h3>
                  {newHeaders.map((header) => (
                    <div key={header.key} className="form-control">
                      <label className="label cursor-pointer w-auto">
                        <span className="label-text">{header.label}</span>
                        <input
                          type="checkbox"
                          checked={selectedNewHeaders.includes(header.key)}
                          onChange={() => {
                            if (selectedNewHeaders.includes(header.key)) {
                              setSelectedNewHeaders((current) =>
                                current!.filter((curr) => curr !== header!.key)
                              )
                            } else {
                              setSelectedNewHeaders((current) => [...current!, header.key])
                            }
                          }}
                          className="checkbox"
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <EnricherTitle>Export enriched file</EnricherTitle>
                <EnricherBody>
                  <strong>We are done! </strong>Select columns to be included in the resulting
                  enriched file. <br />
                  Export CSV file by pressing the button below
                </EnricherBody>
                <div>
                  <CSVLink
                    filename={oldFilename.replace(
                      '.',
                      `- Enriched by ${process.env.REACT_APP_COMPANY_NAME}.`
                    )}
                    data={newRows}
                    headers={newHeaders.filter((header) => selectedNewHeaders.includes(header.key))}
                    className="btn btn-primary"
                  >
                    Export CSV file
                  </CSVLink>
                </div>
                <div className="flex row">
                  <div
                    className="stats shadow hover:shadow-md mt-8 cursor-pointer mr-4 flex-1"
                    style={{ maxWidth: '50%' }}
                    onClick={() => setResultFilter(undefined)}
                  >
                    <div className="stat">
                      <div className="stat-title">Successful</div>
                      <div className="stat-value">
                        {newRows.filter((row) => row['latitude']).length}
                      </div>
                      <div className="stat-desc">out of {newRows.length}</div>
                    </div>
                  </div>
                  {newRows.filter((row) => !row['latitude']).length > 0 && (
                    <div
                      className="stats shadow hover:shadow-lg mt-8 cursor-pointer flex-1 mr-4"
                      onClick={() =>
                        setResultFilter(
                          (_: unknown) => (row: Record<string, string>) => !row['latitude']
                        )
                      }
                    >
                      <div className="stat bg-red-400 text-white">
                        <div className="stat-title opacity-100">Unsuccessful</div>
                        <div className="stat-value">
                          {newRows.filter((row) => !row['latitude']).length}
                        </div>
                        <div className="stat-desc">out of {newRows.length}</div>
                      </div>
                    </div>
                  )}
                  {newRows.filter(outlierRow).length > 0 && (
                    <div
                      className="stats shadow hover:shadow-lg mt-8 cursor-pointer flex-1"
                      onClick={() => setResultFilter((_: unknown) => outlierRow)}
                    >
                      <div className="stat bg-indigo-400 text-white">
                        <div className="stat-title opacity-100">Possible Outliers</div>
                        <div className="stat-value">{newRows.filter(outlierRow).length}</div>
                        <div className="stat-desc">out of {newRows.length}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </EnricherHeroContent>
            <DataTable
              headers={newHeaders.filter((header) => selectedNewHeaders.includes(header.key))}
              rows={newRows}
              onRowDeleteClick={onNewRowDeleteClick}
              numbOfComplete={numbOfComplete}
              editValueFunction={(index, key) => {
                return (value) => {
                  if (oldRows[index][key] !== undefined) {
                    setOldRows((current) => {
                      current![index][key] = value
                      return current
                    })
                    syncLocalStorage(OLD_FILE_ROWS, oldRows)
                  } else {
                    setSearchResults((current) => {
                      current![index] = { ...current![index], [key]: value }
                      return current
                    })
                    syncLocalStorage(SEARCH_RESULTS, searchResults)
                  }
                }
              }}
              refreshFunction={(index) => async () => {
                const searchText = Object.entries(oldRows[index])
                  .filter(([key, _]) => selectedOldHeaders.includes(key))
                  .map(([_, value]) => value)
                  .join(' ')
                const result = await GMapsGeocoder(searchText)
                setSearchResults((current) => {
                  current![index] = result ? result[0] : null
                  return current
                })
                setNewRows([
                  ...oldRows.map((oldRow, index) => {
                    return { ...oldRow, ...searchResults[index] }
                  }),
                ])
                syncLocalStorage(SEARCH_RESULTS, searchResults)
              }}
              filter={resultFilter}
            />
          </>
        )}
    </EnricherHero>
  )
}
